import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, Link } from "gatsby"
import dayjs from "dayjs"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PostTemplate = ({ data, pageContext }) => {
  const { frontmatter, body, timeToRead } = data.mdx
  const { title, publishedAt, coverImage, summary, imageName } = frontmatter

  const prev = pageContext.prev
    ? {
        url: `${pageContext.prev.fields.slug}`,
        title: pageContext.prev.frontmatter.title,
      }
    : null

  const next = pageContext.next
    ? {
        url: `${pageContext.next.fields.slug}`,
        title: pageContext.next.frontmatter.title,
      }
    : null

  return (
    <Layout>
      <Seo title={title} description={summary} />
      <div className="min-h-screen py-4 px-4 mx-auto max-w-screen-sm md:max-w-2xl flex flex-col">
        <div className="flex-grow">
          <h1 className="font-bold text-3xl md:text-5xl tracking-normal mb-4 md:mb-6 text-primary">
            {title}
          </h1>
          <div className="flex space-x-1 text-sm text-gray-500">
            <time dateTime={publishedAt}>
              {dayjs(publishedAt).format("YYYY[年]MM[月]DD[日]")}
            </time>
            <span aria-hidden="true">&middot;</span>
            <span>{`预计 ${timeToRead * 2} 分钟阅读`}</span>
          </div>
          {coverImage?.childImageSharp && (
            <GatsbyImage
              image={getImage(coverImage)}
              alt={imageName}
              className="mb-8 rounded-lg"
            />
          )}

          <div className="text-secondary prose-lg">
            <MDXRenderer>{body}</MDXRenderer>
          </div>

          <div className="mt-8 flex justify-between text-emerald">
            {prev ? (
              <div className="hover:text-emerald-hover text-left">
                <Link to={prev.url}>
                  <span className="flex items-top items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>{" "}
                    上一篇
                  </span>
                  <span className="hidden md:block">{prev.title}</span>
                </Link>
              </div>
            ) : (
              <div />
            )}
            {next && (
              <div className="hover:text-emerald-hover text-right">
                <Link to={next.url}>
                  <span className="flex justify-end items-center">
                    下一篇
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </span>
                  <span className="hidden md:block">{next.title}</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        publishedAt
        summary
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        imageName
      }
      body
      timeToRead
      fields {
        slug
      }
    }
  }
`

export default PostTemplate
